html
{
  box-sizing: border-box;
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}

*,
*:before,
*:after
{
  box-sizing: inherit;
}

body
{
  margin:0;
  background: #ffffff !important;
  overflow: hidden;
}
.bodyColor{
  background: #ebeaea;
}
.container{
  min-height: calc(-180px + 100vh);
  background: #ffffff;
}
.top-nav-bar{
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  overflow: hidden;
  background: #ffffff;
  padding: 5px 15px;
}
.top-nav-wrap{
    width: 100%;
    margin: 0 auto;
}
.nav-bar-icon{
  margin-top: 1px;
  float: left;
  display: block;
  text-decoration: none;
  
}
.swagger-ui {
    width: 100% ;
    margin: 0 auto;
}
.swagger-ui .wrapper{
    padding: 0px !important;
}
.swagger-ui .auth-btn-wrapper{
    justify-content: start !important;
}
.nav-bar-icon img{
  width: 300px;
}
.nav-bar-title{
  float: left;
  display: block;
  text-decoration: none;
  margin-top: 7px;
  margin-left: 10px;
  font-size: 18px;
  color: #ffffff;
  font-family: sans-serif;
}
.nav-bar-select{
  width: 371px;
  float: right;
  font-family: sans-serif;
  display: inline-block;
  cursor: pointer;
  padding: 10px 15px;
  outline: 0;
  border-radius: 2px;
  border: none;
  background: #ffffff;
  color: #3b4151;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
select.classic {
  background-image: linear-gradient(45deg, transparent 50%, #111 50%), linear-gradient(135deg, #111 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 3.5em 3.5em;
  background-repeat: no-repeat;
}
.swagger-ui .scheme-container {
margin: 0 0 20px;
padding: 30px 0;
background: #fafafa !important;
box-shadow: none !important;
}
.Api-footer {
    background-color: #0a266c;
    color: #fff;
    font-size: 14px;
    margin-left: -15px;
    margin-right: -15px;
    padding-bottom: 2px;
    padding-top: 5px;
    text-align: center;
}
.copyright {
    font-size: 13px;
    font-style: normal!important;
    line-height: 33px;
    margin: 0;
    padding-left: 15px;
    text-align: center;
    font-family: sans-serif;
}
#swagger-ui{
    min-height: calc(100vh - 180px);
}
.swagger-ui .btn.authorize, .swagger-ui .btn.execute{
    background-color: transparent;
    background: radial-gradient(circle,#0a266c 0,#085858 3%,#0a266c 94%);
    border: none!important;
    color: #fff!important;
    font-size: 14px!important;
    text-transform: capitalize!important;
    transition: .6s!important;
}
 .swagger-ui .btn.execute:hover{
  background: linear-gradient(to bottom, rgb(93 196 255) 0%, rgb(13 39 107) 100%) !important;
  transition: .6s !important;
}
.swagger-ui .authorize:hover {
  background: linear-gradient(to bottom, rgb(93 196 255) 0%, rgb(13 39 107) 100%) !important;
  transition: .6s !important;
    
}
.swagger-ui .btn{
    color: #0a266c !important;
    border: 2px solid #0a266c !important;
}
.swagger-ui .btn.authorize svg {
    fill: #ffffff !important;
}
.try-out__btn:hover, .btn-clear:hover, .download-contents:hover{
  background: linear-gradient(to bottom, rgb(93 196 255) 0%, rgb(13 39 107) 100%) !important;
  transition: .6s !important;
  color: #ffffff !important;
}
.swagger-ui .download-contents {

  background: radial-gradient(circle,#0a266c 0,#085858 3%,#0a266c 94%);
  border: none!important;
  color: #fff!important;
  padding: 8px;
  font-size: 12px;
  height: 30px;
  width: 80px;
  right: 25px !important;
}
.swagger-ui .opblock-body pre.microlight{
  background: #000000 !important;
}

.swagger-ui .opblock-tag-section {
  display: flex;
  flex-direction: column;
}

.swagger-ui .expand-methods svg, .swagger-ui .expand-operation svg {
  width: 20px !important;
  height: 20px !important;
  fill: #0a266c !important;
}
.swagger-ui .opblock-control-arrow svg{
  width: 20px !important;
  height: 20px !important;
}
.swagger-ui .info a {
  font-size: 0px;
  transition: all .4s;
  opacity: 0.5; 
  font-family: sans-serif;
  color: #fff !important;
}

.swagger-ui .opblock-body select {
  min-width: 333px;
  height: 36px;
}

.swagger-ui select {
 
  border: 1px solid #0a266c !important;
  
}
.swagger-ui .info .base-url,.information-container{
  display: none;
}
.title-top {
  font-size: 36px;
  margin: 0;
  font-family: sans-serif;
  color: #3b4151;
  margin-bottom: 20px;
}
.title-wrap{
  width: 100%;
  margin: 0 auto;
}
.title-wrap i{
  padding-left: 10px;
  display: block;
  margin-bottom: 20px;
}
.metadata .btn.authorize{
  background: radial-gradient(circle,#0a266c 0,#085858 3%,#0a266c 94%) !important;
    border: none!important;
    color: #fff!important;
    font-size: 14px!important;
    text-transform: capitalize!important;
    transition: .6s!important;
    padding: 3px 15px;
    line-height: 1.8;
    display: inline;
    border-radius: 6px;
    margin-left: 10px;
    
}
.metadata .btn.authorize a{
  color:#ffffff;
  text-decoration: none;
}

.swagger-ui .scheme-container{
  display: none !important;
}

.swagger-ui .authorization__btn {
  visibility: hidden !important;
}
.swagger-ui .copy-to-clipboard{
  right: 110px !important;
}
.hide-in-iframe {
  display: none !important;
}
.titlewrap-in-iframe{
margin-top: 0px !important;
}
.swagger-ui .opblock-tag{
  flex-direction: column;
}
.metadata {
  text-align: start !important;
}
@media screen and (min-width: 767px){
  .swagger-ui, .title-wrap, .top-nav-wrap {
    width: 100%;
     }
     .container{
      padding: 0 25px;
    }
    .swagger-ui .opblock-tag{
      flex-direction: column;
    }
    .metadata {
      text-align: end !important;
    }

  }
  @media screen and (min-width: 1366px){
    .swagger-ui, .title-wrap, .top-nav-wrap {
      width: 1366px;
       }
       .swagger-ui .opblock-tag{
        flex-direction: row;
      }
      .title-wrap i{
        padding-left: 0px;
        display: inline-block;
        margin-bottom: 0px;
      }
      .metadata {
        text-align: end !important;
      }
    }
@media screen and (min-width: 1169px) and (max-width: 1366px){
  .swagger-ui, .title-wrap, .top-nav-wrap {
    width: 1170px;
     }
     .container{
      padding: 0 0;
    }
    .swagger-ui .opblock-tag{
      flex-direction: row;
    }
    .title-wrap i{
      padding-left: 0px;
      display: inline-block;
      margin-bottom: 0px;
    }
    .metadata {
      text-align: end !important;
    }
  }
 
  