@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html, body{
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans', sans-serif !important;
  font-size: 15px;
  line-height: 1.4;
}
h1, h2, h3, h4, h5, h6, button, a, label, p{
  font-family: 'Noto Sans', sans-serif !important;
}
h5{
  color: #0d276b;
  font-weight: 700 !important;
  font-size: 20px;
}
h4.pods-count{
margin:0 !important;
font-size: 30px;
}

a, a:focus, a:focus-visible, a:visited, a:hover{
  outline: none;
}
.container { 
  min-height:100vh;
  display:flex;
  font-family: Arial
}

.left { 
  min-height:25vh;
  background-color: rgb(50, 67, 151) ;
  width: 25%;
  color: white;
}
.right { 
  padding-left: 10px;
  min-height:75vh;
  width: 75%;
}
.page-body{
  padding-top: 30px !important;

}
.bg-grey{
  background: #ebeaea !important;
}
.innerpage-body,.page-body{
  width: 1366px;
  margin: 0 auto;
  padding: 0 15px;
  background: #ffffff;
}
/* new css by bhupender */
.mospi-dp-mainNav .MuiTab-root{
  font-size: 14px;
  text-transform: Capitalize;
}
.mospi-table table, .mospi-table table tr td{
  font-size: 13px;
}

.catalogue-search .search-box .search-drpdwn{
  margin-bottom: 15px;
}
.data-table-header{
  padding: 0px;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}
.MuiButton-outlinedPrimary{
  border: 1px solid rgba(10, 38, 108,.5) !important;
    color: rgba(10, 38, 108,1) !important;
}
.btn-primary, .MuiButton-containedPrimary, .primary-btn{
  color: #ffffff !important;
  border: none !important;
  font-size: 20px !important;
  background: rgb(10, 38, 108);
  background: radial-gradient(circle, rgba(10, 38, 108,1) 0%, rgba(8,88,88,1) 3%, rgba(10, 38, 108,1) 94%);
  transition: 0.6s !important;
  text-transform: capitalize !important;
}
.btn-primary:hover, .MuiButton-containedPrimary:hover, .primary-btn:hover{
  background: radial-gradient(circle, rgba(10, 38, 108,1) 0%, rgba(8,88,88,1) 30%, rgba(10, 38, 108,1) 74%);
  transition: 0.6s;
}
.catalog-grid h5{
  font-size: 24px !important;
}
.catalogue-search .search-box .mospi-searchbox{
  width: 100%;
}

.mospi-searchbox .MuiTextField-root .MuiOutlinedInput-root .MuiAutocomplete-input{
  padding: 4px 28px 4px 5px !important;
}
.mospi-searchbox .MuiTextField-root .MuiOutlinedInput-root{
  padding: 6.5px !important;
  background: #ffffff !important;
}
.catalogue-header .MuiSelect-select{
  padding: 10.5px 28px 10.5px 14px !important;
  background: #ffffff !important;
  font-size: 13px !important;
}
.MuiButton-containedPrimary{
font-size: 16px !important;
}
.catalogue-header .MuiFormLabel-root{
  font-size: 13px !important;
  line-height: 2 !important;
}
.search-drpdwn .MuiFormLabel-root{
  line-height: 2 !important;
  font-size: 13px !important;
}
.catalogue-header .MuiFormLabel-root.Mui-focused{
  line-height: 2 !important;
}
.catalogue-header .MuiFormLabel-root::selection{
  line-height: 2 !important;
}
.catalogue-header label[data-shrink="false"]{
  line-height: 1.1 !important;
}

.disclaimer{
  font-size: 10px !important;
  margin-bottom: -37px !important;
  font-style: italic !important;
}
.footer-links{
 color: #ffffff !important;
 text-decoration: none !important;
}
.footer-links:hover{
  color: #53C5FC !important;
  text-decoration: underline !important;
 }
 a{
  text-decoration: none !important;
 }
 .swagger-container{
  background: #ffffff;
  padding-top: 15px;
 }
 .loadspinner{
    display: flex;
    margin-top: 18%;
    justify-content: center !important;
 }

 .scroll-top{
  position: fixed;
  bottom: 148px;
  right: 20px;
  background: #0d276b;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 9;
 }

/* width */
::-webkit-scrollbar, iframe::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track, iframe::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb, iframe::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover, iframe::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


@media screen and (min-width:768px) {
  .catalogue-search .search-box .search-drpdwn{
    width: 150PX;
    margin-right: 10px;
  }
  .catalogue-search .search-box{
    display: flex;
  }
  
}

.embedLoader-iframe {
  /* background:url(loader.gif) center center no-repeat; */
   background: url(assets/newloader.gif) center 7% no-repeat;
}
.innerpage-body {
  padding-top: 30px !important;
}
.slider{
  width: 100% !important;
}
.card{
  width:300px;
  padding: 20px;
  /* height: 100px; */
  text-align: center;
  border-width: 0 2px 0 0;
  border-style: solid;
  border-color: #ebeaea;
  -webkit-transition: box-shadow 0.5s, -webkit-transform 0.5s;
	transition: box-shadow 0.5s, transform 0.5s;
}
.card:first-child{
  border-width: 0 2px 0 2px;
}
.card:hover{
  background: #ebeaea;
  -webkit-box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-transition: box-shadow 0.5s, -webkit-transform 0.5s;
	transition: box-shadow 0.5s, transform 0.5s;
}
.arrowIcon{
 color: rgba(10, 38, 108, 1) !important;
 font-weight: 900;
 padding: 0px !important;
}
.arrowIcon svg{
  font-size: 80px !important;
  width: .5em !important;
 }
 .arrowIcon:hover{
  background: transparent !important;
  color: rgba(0, 0, 0, 1) !important;
 }
.arrowIcon.Mui-disabled{
  color: rgba(10, 38, 108, .5) !important;
 }


 .hide{
	display: none !important;
}
.show{
	display: flex !important;
}










@media screen and (max-width:767px) {
  .innerpage-body, .page-body{
  width: 100%;
  }
  .arrowIcon svg {
    font-size: 50px !important;
    width: .4em !important;
}
.arrowIcon{
  min-width: 35px !important;
}
.inner-1 {
  padding-right: 0px;
}
.disclaimer{
  margin-bottom: 0px !important;
}
}
@media screen and (min-width:1024px) {
  .mospi-dp-mainNav {
    margin-top: 10px;
  }
  .mospi-dp-mainNav .MuiTab-root{
    font-size: 22px;
    line-height: 1.8;
  }
  .mospi-table table, .mospi-table table tr td{
    font-size: 14px !important;
  }
}
@media screen and (min-width:1169px) and (max-width:1365px) {
  .innerpage-body,.page-body, .hp-body, .header-wrap{
    width: 1170px !important;
  }

}

