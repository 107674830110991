/******section-3-css start****/
.section-box .text-wrap{
    padding: 20px;
    background: #fff;
}
.section-box {
    margin-top: 48px !important;
}
.hdcount-wrap{
    width: 100%;
}
.count{
    background:#ebeaea;
    padding:10px;
    color:#0d276b;
    border-radius:50%;
    font-weight:bold;
    float:left;
    margin-right: 10px;
  }

   .box p{
    color: #333333 !important;
    text-shadow:none;
    margin-bottom: 0px !important;
    margin-top: 4px !important;
  }
  .box h5{
    color: #0d276b !important;
    font-weight: 700 !important;
    font-size: 1.5rem !important;
    line-height: 1.7;
  }
  .linkbox-row{
    width:100%;
    display: flex;
    justify-content: space-between;
    clear:both;
  }
  .linkbox-row svg{
    width: 37px;
    height: 37px;
  }
  .section-box .inner-1{
     padding-right: 0 !important;
  }
  svg{
    float:right;
  }
  .box {
    position: relative;
    display: block;
    place-content: center;
    /* text-shadow: 0 1px 0 #000; */
    padding:15px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
      box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    --border-angle: 0turn; /* For animation. */
    --main-bg: conic-gradient(
        from var(--border-angle),
        #ffffff,
        #f3f3f3 5%,
        #ebeaea 60%,
        #ffffff 95%
      );
    
    border: solid 1px #EBEAEA;
    border-radius: 2em;
    /* border-image: linear-gradient(to right, #0a266c, #005788);
    border-image-slice: 1;
    background-clip: border-box; */
    --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #005788, #0A266C 99%, transparent);
    
    /* background:  */
      /* padding-box clip this background in to the overall element except the border. */
      /* var(--main-bg) padding-box, */
      /* border-box extends this background to the border space */
      /* var(--gradient-border) border-box,  */
      /* Duplicate main background to fill in behind the gradient border. You can remove this if you want the border to extend "outside" the box background. */
      /* var(--main-bg) border-box; */
    
    /* background-position: center center;
  
    animation: bg-spin 5s linear infinite; */
    -webkit-transition: background-color .5s linear ease-in-out;
    -ms-transition: background-color .5s linear ease-in-out;
    transition: background-color .5s linear ease-in-out;
  }
  .box:hover{
    background: #EBEAEA;
    border: solid 1px #EBEAEA;
    -webkit-transition: background-color .5s linear ease-in-out;
    -ms-transition: background-color .5s linear ease-in-out;
    transition: background-color .5s linear ease-in-out;
  }
  .box:hover .count{
     background: #ffffff;
  }
  .Nsstiles h6{
    font-weight: 800;
    color: #0A266C;
    font-size: 16px;
    font-style: normal;
    margin: 20px 0 0 0 !important;
  }
  .Nsstiles p{
    margin: 10px 0 0 0 !important;
  }
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
  
  .box:hover {
    animation-play-state: paused;
  }
  
  @property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }
  .transionpage-item:last-child{
   margin-bottom: 30px;
  }

.box1{
  padding: 15px;
  display: flex;
}
.box1 span{
  display: flex;
  align-items: center;
  color: #0A266C;
}
.box1:hover span{
  color: #ffffff;
}
.box2{
  padding: 15px;
}
.nssbox.MuiCardContent-root{
  padding: 0px !important;
}
.ecbox h5{
  font-size: 28px !important;
}
 @media screen and (max-width:767px) {
  .transionpage-item{
    padding-right: 32px;
  }
  .ecbox h5{
    font-size: 22px !important;
  }
 }
/******section-3-css end****/