* {
    box-sizing: border-box;
}

.udp-hp-wrapper {
    background-color: #ffffff !important;
    min-height: 100vh;
    height:100vh;
    padding: 0 0 !important;
    overflow-x: hidden;
}

.udp-hp-wrapper .mospi-dp-header {
    margin-left: -15px;
    margin-right: -15px;
}

.hp-body {
    display: flex;
    flex-direction: column;
    width: 1366px;
    margin-bottom: 0px;
    margin: 0 auto;
}
.hp-body h2{
    color: #0A266C;
    font-size: 38px; 
    font-weight: bold ;
}

.hp-body>div{
    margin: 0;
    /* padding: 10px; */
    width: 100%;
}

.hp-body .map-image {
    width: 100%;
}

.hp-body .left-box {
    display: flex;
    flex-direction: column;
    padding: 70px !important;
    width: 100%;
    background: #EBEAEA;
}
.hp-body .right-box {
    display: flex;
    flex-direction: column;
    padding: 10px !important;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.hp-body .left-box h3 {
    font-size: 2rem;
    font-weight: 700;
}

.hp-body .left-box .subtitle {
    font-size: 1.3rem;
}

.anim-text {
    width: 100%;
}

.hp-search {
    margin: 20px 0;
}

.grid-boxes {
    display: flex;
    /* flex-direction: row;
    list-style-type: none;
    margin: 15px 0; */
    width:50%;
}
.grid-boxes p{
    text-align: justify;
}
.grid-boxes div {
    flex:1;
    border: 2px rgba(0,0,0,0.6) solid;
    border-radius: 5px;
    padding: 20px;
    margin: 0 10px;
    position: relative;
}

.grid-boxes div a {
    text-decoration: none;
    color: #000000;
    display: block;
}

.grid-boxes div h6 {
    font-size: 1.5rem;
    font-weight: 500;
}

.grid-boxes div:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: #2196f3;
}

.grid-boxes div:nth-child(2):after {
    background-color: #ff7b66;
}

.hp-body .catalogue-search .search-box .main-searchbox {
    width: 100%;
    max-width: 441px;
}
.hp-body .right-box{
    padding: 60px !important;
}
.right-box h2{
    margin: 0px;
    line-height: 2;
}
.map-footer {
    font-size: 12px;
    text-align: right;
}
.btn-icon{
    margin-left: 10px;
    width: 32px;
}
.btn-icon-externel{
    margin-left: 10px;
    width: 26px;
}
.btn-primary{
    color: #ffffff !important;
    border: none !important;
    font-size: 20px !important;
    background: rgb(10, 38, 108);
    background: radial-gradient(circle, rgba(10, 38, 108,1) 0%, rgba(8,88,88,1) 3%, rgba(10, 38, 108,1) 94%);
    transition: 0.6s !important;
    text-transform: capitalize !important;
}
.btn-primary:hover{
    background: radial-gradient(circle, rgba(10, 38, 108,1) 0%, rgba(8,88,88,1) 30%, rgba(10, 38, 108,1) 74%);
    transition: 0.6s;
}



.section-2{
    margin-bottom: 48px !important;
}
.section-3 .inner-1 .image-wrap{
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 250px;
}

.inner1-content, .inner2-content{
    background: #EBEAEA;
}
.search-content h5{
    font-size: 20px !important;
}

.section-3 .inner-2 .image-wrap{
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 250px;
}
.section-3 .text-wrap{
    padding: 20px;
    background: #EBEAEA;
}
.pods-box{
    background: transparent;
    padding: 5px 35px;

}
.pods{
    padding: 10px;
}
.pods-icon-wrap{
    background: #EBEAEA;
    padding: 10px;
    border-radius: 50%;
    text-align: center;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    position: relative;
    overflow: auto;
}
.pods-icon-box img{
    width: 45px;
    height: 45px;
}
.pods-count-box{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}
.arrow{
    opacity: .5;
    width: 35px !important;
    height: 35px !important;
}
.card .downarrow{
    transform: rotateX(180deg);
  }
  .card:not(style)~:not(style){
    margin-left: 0px !important;
  }
.pod-h h5{
    font-weight: 800;
    color: #0A266C;
    margin: 0px !important;
}
.astrik{
    font-size: 11px;
    font-weight: 700;
    font-style: italic;
}
h6{
    font-size: 9px;
    font-weight: 600;
    line-height: 0.1;
    font-style: italic;
}
.period{
    font-size: 16px;
    font-weight: 700;
    color: rgba(10, 38, 108, .5);
    font-style: normal;
    margin: 12px 0;
}
  h3.card-title{
    font-size: 22px  !important;
    font-weight: bold !important;
    color: rgba(10, 38, 108, 1) !important;
    margin: 0 !important;
  }
.pods-count{
    font-size: 21px;
    color: rgba(10, 38, 108, .5);
    font-weight: 800;
    line-height: 1;
}

  .search-wrap{
    margin-bottom: 48px !important;

  }
  .main-search{
    background: rgb(13 39 107 / 10%);
    padding: 48px !important;
  }
  .search-box{
    border-radius: 22px;
    text-align: center;

  }
  .search-box .MuiFormLabel-root, .search-drpdwn .MuiFormLabel-root{
   font-size: 13px;
   line-height: 2;
  }
.search-icon{
    width: 22px;
    margin-right: 5px;
}
.search-content{
    margin: 0 auto !important;
}
.option-link{
    color: #333 !important;
}
.btn-search{
    font-size: 14px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    background: radial-gradient(circle, rgba(10, 38, 108, 1) 0%, rgba(8, 88, 88, 1) 3%, rgba(10, 38, 108, 1) 94%);
    transition: 0.6s !important;
    text-transform: capitalize !important;
    color: #ffffff !important;
}

.btn-search:hover{
    background: radial-gradient(circle, rgba(10, 38, 108, 1) 0%, rgba(8, 88, 88, 1) 30%, rgba(10, 38, 108, 1) 74%);
    transition: 0.6s;
    border: 1px solid transparent !important;
}
.pods h5{
    font-size: 14px;
    color: #0a266c;
    font-weight: 800 !important;
    margin: 0 !important;
  }

  .main-searchbox{
    z-index: 999;
    position: relative;
    
  }

  .main-searchbox .MuiInputBase-root{
    border-radius: 30px !important;
    box-shadow: 0 1px 6px 0 rgba(10, 38, 108, .3);
    background: #ffffff;

  }

  .custom-popper{
    margin-top: -24px !important;
  }
  
  .custom-popper .MuiAutocomplete-paper{
     padding-top: 25px;
  }

  .catlogbtn-wrap {
    align-items: end;
    display: flex;
    justify-content: space-between;
}
.reference {
    float: right;
    font-size: 9px;
    font-weight: 600;
    line-height: .1;
}

.list-link{
    color:#333333;
    cursor: pointer;
}
.list-link:hover{
    color:#0A266C;
}









@media only Screen and (min-width: 768px) {
    .hp-body .left-box h3 {
        font-size: 3rem;
    }

    .hp-body {
        text-align: left;
    }

    .grid-boxes {
        margin-top: 25px;
    }

    .search-box .css-gcwvw8-MuiAutocomplete-root {
        min-width: 441px;
    }

    .grid-boxes div:nth-child(2n) {
        margin-right: 0;
    }
    .body-home{
        padding: 50px 20px !important;
      }
}

@media only Screen and (min-width: 1024px) {
    .hp-body {
        min-height: calc(100vh - 167px) !important;
    }
}
@media only Screen and (max-width:767px){
    .hp-body{
        width: 100%;
    }
    .hp-body .left-box{
        padding: 50px !important;
    }
    .section-2 .image-wrap{
        padding: 150px !important;
    }
    .break{
        display: none;
    }
    .pod1 .col-h{
        width: 100% !important;
    }
    .namaste-bhaarat, .header-wrap a{
        text-align: center;
    }
    .col-1,.col-2{
        width: 50%;
    }
    .section-1 .image-wrap{
        padding: 120px 20px !important;
    }
    .mospi-link{
        float: left !important;
       }
       .col-h{
        width: 100%;
       }

      .main-search{
        border-radius: 22px;
        text-align: center;
        padding: 15px !important;
      }
      .body-home{
        padding: 15px !important;
      }

      .section-1, .section-2{
        margin-bottom: 20px !important;
    }
    .section-3 .inner-1 .image-wrap, .section-3 .inner-2 .image-wrap {
        padding: 100px;
    }
    .inner-1{
        padding-top: 0 !important;
        padding-left: 0 !important;
    }
    .inner-2{
        padding-top: 20px !important;
        padding-left: 0 !important;
    }
    .inner-3{
        padding-top: 20px !important;
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
    .catlogbtn-wrap {
        display: block;
    }
    .catlogbtn-wrap button{
      margin-bottom: 10px;
    }
    .reference {
        float: none;
        line-height: normal;
    }
    .search-content h5 {
        font-size: 20px !important;
    }
}


h1 {
    color: #5e6a84;
    font-size: 3rem;
    line-height: 1.5;

    white-space: nowrap;
    overflow: hidden;
    position: relative;
    width: 550px;
}

h1 span {
    font-size: 40px;
    margin-left: 40px;
}

.message {
    background-color: rgb(209, 224, 255);
    color: #333;
    text-align: center;
    padding: 0 1rem;
    border-radius: 10px;
    font-weight: 600;
    width: 12em;
    font-size: 36px;
}

.containerScroll {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 2rem;
    min-height: 600px;
    border-radius: 2rem;
    background-color: rgb(209, 224, 255);
}

.orange-bg {
    background: rgb(255, 229, 200) !important;
}

.p-2 {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0 !important;
    padding: 0rem !important;
}

.containerScroll-img>* {
    width: 40vw;
    height: auto;
    border-radius: 10px;
}

.footer {
    display: block;
    text-align: center;
    background-color: #0A266C;
    color: rgb(255, 255, 255);
    padding: 10px;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 14px;
}
.mospi-link{
    float: right;
    color: #ffffff;
   }