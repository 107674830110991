* {
    box-sizing: border-box;
}

.udp-hp-wrapper {
    background-color: #ffffff;
    min-height: 100vh;
    height:100vh;
    padding: 0 0 !important;
    overflow-x: hidden;
}

.udp-hp-wrapper .mospi-dp-header {
    margin-left: -15px;
    margin-right: -15px;
}

.hp-body {
    display: flex;
    flex-direction: column;
    width: 1366px;
    margin-bottom: 0px;
    margin: 0 auto;
}
.hp-body h2{
    color: #0A266C;
    font-size: 38px; 
    font-weight: bold ;
}

.hp-body>div{
    margin: 0;
    /* padding: 10px; */
    width: 100%;
}

.hp-body .map-image {
    width: 100%;
}

.hp-body .left-box {
    display: flex;
    flex-direction: column;
    padding: 70px !important;
    width: 100%;
    background: #EBEAEA;
}
.hp-body .right-box {
    display: flex;
    flex-direction: column;
    padding: 10px !important;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.hp-body .left-box h3 {
    font-size: 2rem;
    font-weight: 700;
}

.hp-body .left-box .subtitle {
    font-size: 1.3rem;
}

.anim-text {
    width: 100%;
}

.hp-search {
    margin: 20px 0;
}

.grid-boxes {
    display: flex;
    /* flex-direction: row;
    list-style-type: none;
    margin: 15px 0; */
    width:50%;
}
.grid-boxes p{
    text-align: justify;
}
.grid-boxes div {
    flex:1;
    border: 2px rgba(0,0,0,0.6) solid;
    border-radius: 5px;
    padding: 20px;
    margin: 0 10px;
    position: relative;
}

.grid-boxes div a {
    text-decoration: none;
    color: #000000;
    display: block;
}

.grid-boxes div h6 {
    font-size: 1.5rem;
    font-weight: 500;
}

.grid-boxes div:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: #2196f3;
}

.grid-boxes div:nth-child(2):after {
    background-color: #ff7b66;
}

.hp-body .catalogue-search .search-box .mospi-searchbox {
    width: 100%;
    max-width: 441px;
}
.hp-body .right-box{
    padding: 60px !important;
}
.right-box h2{
    margin: 0px;
    line-height: 2;
    margin-bottom: -26px;
}
.map-footer {
    font-size: 12px;
    text-align: right;
}
.btn-icon{
    margin-left: 10px;
    width: 32px;
}
.btn-icon-externel{
    margin-left: 10px;
    width: 26px;
}
.btn-primary{
    color: #ffffff !important;
    border: none !important;
    font-size: 20px !important;
    background: rgb(10, 38, 108);
    background: radial-gradient(circle, rgba(10, 38, 108,1) 0%, rgba(8,88,88,1) 3%, rgba(10, 38, 108,1) 94%);
    transition: 0.6s !important;
    text-transform: capitalize !important;
}
.btn-primary:hover{
    background: radial-gradient(circle, rgba(10, 38, 108,1) 0%, rgba(8,88,88,1) 30%, rgba(10, 38, 108,1) 74%);
    transition: 0.6s;
}


.section-1 .image-wrap{
    background-image: url('../../assets/worldcloud.webp');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.section-2 .image-wrap{
    /* background-image: url('../../assets/tech-background.webp '); */
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.pods-wrap{
    background: #ffffff;
    padding: 15px;
    border-radius: 20px;
    -webkit-box-shadow: -1px -2px 84px -23px rgba(10,38,108,0.8);
    -moz-box-shadow: -1px -2px 84px -23px rgba(10,38,108,0.8);
    box-shadow: -1px -2px 84px -23px rgba(10,38,108,0.8);
}
.pods{
    padding: 10px;
}
.pods-icon-wrap{
    background: #EBEAEA;
    padding: 10px;
    border-radius: 50%;
    text-align: center;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    position: relative;
    overflow: auto;
}
.pods-icon-wrap img{
    width: 45px;
    height: 45px;
}
.col-h h5{
    font-weight: 800;
    color: #0A266C;
    margin: 0px !important;
}
.astrik{
    font-size: 11px;
    font-weight: 700;
    font-style: italic;
}
h6{
    font-size: 9px;
    font-weight: 600;
    line-height: 0.1;
    font-style: italic;
}
  
.tooltip1 .tooltiptext1, .tooltip2 .tooltiptext2, .tooltip3 .tooltiptext3, .tooltip4 .tooltiptext4 {
    visibility: hidden;
    width: 80px;
    height: 80px;
    background-color: #0A266C;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    padding: 5px 0;
    font-weight: bold;
    font-size: 14px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tooltip1:hover .tooltiptext1,.tooltip2:hover .tooltiptext2,.tooltip3:hover .tooltiptext3, .tooltip4:hover .tooltiptext4 {
    visibility: visible;
  }
  .cat-reference {
    color: #0a266c !important;
    font-weight: 700;
    font-size: 11px;

   }
   .catalog-pods .pods h5{
    font-size: 11.7px !important;
   }
@media only Screen and (min-width: 768px) {
    .hp-body .left-box h3 {
        font-size: 3rem;
    }

    .hp-body {
        text-align: left;
    }

    .grid-boxes {
        margin-top: 25px;
    }

    .search-box .css-gcwvw8-MuiAutocomplete-root {
        min-width: 441px;
    }

    .grid-boxes div:nth-child(2n) {
        margin-right: 0;
    }
}

@media only Screen and (min-width: 1024px) {
    .hp-body {
        min-height: calc(100vh - 167px);
    }
}
@media only Screen and (max-width:767px){
    .hp-body{
        width: 100%;
    }
    .hp-body .left-box{
        padding: 50px !important;
    }
    .section-2 .image-wrap{
        padding: 150px !important;
    }
    .break{
        display: none;
    }
    .pod1 .col-h{
        width: 100% !important;
    }
    .namaste-bhaarat, .header-wrap a{
        text-align: center;
    }
    .col-1,.col-2{
        width: 50%;
    }
    .section-1 .image-wrap{
        padding: 120px 20px !important;
    }
    .mospi-link{
        float: left !important;
       }
       .col-h{
        width: 100%;
       }
}


h1 {
    color: #5e6a84;
    font-size: 3rem;
    line-height: 1.5;

    white-space: nowrap;
    overflow: hidden;
    position: relative;
    width: 550px;
}

h1 span {
    font-size: 40px;
    margin-left: 40px;
}

.message {
    background-color: rgb(209, 224, 255);
    color: #333;
    text-align: center;
    padding: 0 1rem;
    border-radius: 10px;
    font-weight: 600;
    width: 12em;
    font-size: 36px;
}

.containerScroll {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 2rem;
    min-height: 600px;
    border-radius: 2rem;
    background-color: rgb(209, 224, 255);
}

.orange-bg {
    background: rgb(255, 229, 200) !important;
}

.p-2 {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0 !important;
    padding: 0rem !important;
}

.containerScroll-img>* {
    width: 40vw;
    height: auto;
    border-radius: 10px;
}

.footer {
    display: block;
    text-align: center;
    background-color: #0A266C;
    color: rgb(255, 255, 255);
    padding: 10px;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 14px;
}
.mospi-link{
    float: right;
    color: #ffffff;
   }