iframe > .top-nav-bar, .title-top, .Api-footer  {
    display: none !important;
}
.macro-iframe{
    margin: 20px 0;
}

.tabpanel{
    padding: 0px 0 !important;
}
.MuiTab-textColorPrimary{
    font-weight: 600 !important;
}
.MuiTab-textColorPrimary.Mui-selected{
    color: #0A266C !important;
}
.MuiTabs-indicator{
    background: #0A266C !important;
}
ul.gridtable {
    font-size: 16px;
    list-style-type: none;
    margin-bottom: 15px;
    padding: 0 50px;
    line-height: 2;
    background: #ffffff;
}
ul.gridtable>li {
    color: #333;
    padding: 0;
}
.innerpage-body {
    min-height: calc(100vh - 167px);
}

.fullwidth{
    width: 100% !important;
    padding-left: 0px;
    padding-right: 0px;
}
.fullwidth .MuiBox-root{
    padding-left: 50px;
    padding-right: 50px;
}
.macro-title{
    float: right;
    padding-right: 50px;
}
.macro-embed iframe{
    height: 780px;
}
.nmdslink{
    color: #333333;
}
.nmdslink:hover{
    color: #0A266C;
}
.nmdslink svg{
    color: #333333;
    fill: #333333;
    width: 16px;
}
.nmdslink:hover svg{
    color: #0A266C;;
    fill: #0A266C;
}
.tablist button{
    padding: 8px 16px !important;
}
@media screen and (max-width:767px) {
    .macro-embed iframe{
        height: calc(100vh - 410px);
    }
    .fullwidth{
        width: 100% !important;
        padding-left: 15px;
        padding-right: 15px;
    }
    .fullwidth .MuiBox-root{
        padding-left: 0px;
        padding-right: 0px;
    }
    .macro-title{
        float: none;
        padding-right: 0px;
        margin-bottom: 0px !important;
    }
    ul.gridtable {

        padding: 0 0px;

    }
    .tablist button{
        padding: 8px 8px !important;
    }
  }