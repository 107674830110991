.spinner-container{
  text-align: center;
}
.spinner-container span{
  padding-top: 15px;
}
/* @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; 
    border-top: 10px solid #383636; 
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    margin: auto;
  } */

  .holds-the-iframe {
    /* background:url(loader.gif) center center no-repeat; */
    background:url(../../assets/loader.gif) center 20% no-repeat; 
  }