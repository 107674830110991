

/*-----theme pod start---*/
*, *:after, *:before { -webkit-box-sizing: border-box; box-sizing: border-box; }
.clearfix:before, .clearfix:after { content: ''; display: table; }
.clearfix:after { clear: both; }
.theme-pods{
    padding: 48px 8px;
}
.grid {
	overflow: hidden;
	margin: 0;
	width: 100%;
	list-style: none;
	text-align: center;
}

/* Common style */
.grid figure {
	position: relative;
	z-index: 1;
	display: inline-block;
	overflow: hidden;
	margin: -0.135em;
	width: 100%;
	height: 250px;
	background: #3085a3;
	text-align: center;
	cursor: pointer;
}

.grid figure img {
	position: relative;
	display: block;
	min-height: 100%;
	opacity: 0.8;
}

.grid figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: none;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
	pointer-events: none;
}

.grid figure figcaption,
.grid figure a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

.grid figure h5 {
	word-spacing: -0.15em;
	
	font-weight: 300;
}

.grid figure h5 span {
	font-weight: 800;
}

.grid figure h5,
.grid figure p {
	margin: 0;
	margin-top: -5px;
    color: #ffffff;
    font-size: 30px;
}

.grid figure p {
	letter-spacing: 1px;
	font-size: 68.5%;
}

/* Individual effects */

figure.effect-dexter {
	background: rgba(13, 39, 107, 1);
	background-position: 0% 100%;
	background-repeat: no-repeat;
}

figure.effect-dexter img {
	-webkit-transition: opacity 0.35s;
	transition: opacity 0.35s;
}

figure.effect-dexter:hover img {
	opacity: 0.4;
}

figure.effect-dexter figcaption::after {
	position: absolute;
	right: 30px;
	bottom: 85px;
	left: 30px;
	height: -webkit-calc(40% - 30px);
	height: calc(40% - 30px);
	border: 7px solid #ffffff;
	content: '';
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(0,-100%,0);
	transform: translate3d(0,-100%,0);
}

figure.effect-dexter:hover figcaption::after {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
    bottom: 45px;
}

figure.effect-dexter figcaption {
	padding: 2.3em;
	text-align: left;
}

figure.effect-dexter p {
	position: absolute;
	right: 50px;
	bottom: 62px;
	left: 50px;
	font-size: 25px;
	opacity: 0;
	font-weight: 600;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(0,-100px,0);
	transform: translate3d(0,-100px,0);
}

figure.effect-dexter:hover p {
	opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

.macro2 {
	padding: 0 11px;
	margin-bottom: 0 !important;
	}

/*-----theme pod end---*/
.macro-top{
	margin-top: 50px !important;
	padding: 0 20px;
}
.macro-heading{
    background: #0d276b1a;
	padding: 48px 48px !important;

}
.macro-heading h2{
	font-size: 32px !important;
}
.macro-content{
	background: #0d276b1a;
    padding: 38px 48px 38px 0px !important;
}
.macropod-dropdown{
	padding: 0 20px 50px 20px;
	transition: display 0.35s ease-in-out;
}

.macrodropdown-wrap{
	padding: 8px 4px;
}

.macropod:hover{
	background-color:rgba(13, 39, 107, 1);
    transition: background-color 0.8s, transform 0.8s;
}
.macrodropdown-wrap:hover h5{
	color: #fff !important;
}
.macropod h5{
	margin: 0 !important;
	font-size:18px ;
}
.macropod{
	padding: 15px;
	background-color:rgba(13, 39, 107, .2);
    transition: background-color 0.8s, transform 0.8s;
}
.drop-container{
	padding: 20px;
	background: #EBEAEA;
	margin: 0 !important;
}
.macrodropdown-wrap{
	padding-top: 8px !important;
	padding-left: 4px !important;
}
.image-cloud{
	background-image: url('../../assets/worldcloud-small.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
/*-----Mobile-start---*/
@media only Screen and (max-width:767px){

	.macropod-dropdown{
		padding: 0 20px 20px 20px;
	}
	.macro-top{
		margin-top: 20px !important;
	}
    .theme-pods{
        padding: 16px 8px;
    }
	figure.effect-dexter p {
		right: 45px;
		bottom: 60px;
		left: 45px;
	}
	
	.grid figure p {

		font-size: 64.5%;
	}
	.grid figure h5, .grid figure p {
		margin-top: 0px;
		font-size: 20px;
		
	}
	.macro-heading {
		padding: 20px 20px 0px 20px !important;
		width: 100% !important;
	}

	.macro-content{
		padding: 0px 20px 0px 20px !important;
	}
	.drop-container{
		margin: 0 !important;
	}
	
	.macrodropdown-wrap:first-child{
		padding-left: 4px !important;
		padding-top: 8px !important;
	}
	.section-1 .image-cloud{
		padding: 130px 20px !important;
	}

}
 /*-----Mobile-end---*/

 @media screen and (min-width:1169px) and (max-width:1365px) {
    .grid figure h5,
.grid figure p {
    margin-top: 6px;
}
.grid figure h5{
	font-size: 16px;
}
.grid figure p{
	font-size: 14px;
}
.macropod h5 {
	font-size: 16px;
}
  
  }